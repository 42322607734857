/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReachSimulatorApi } from '@/api';
import {
  ReachSimulatorOverview,
  ReachSimulatorOverviewStatusEnum
} from '@/api/openapi';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { PLANNING_ROUTES, ROUTE_NAMES } from '@/router';
import Axios from 'axios';
import { Ref, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { FilterMatchMode, FilterOperator } from 'primevue/api';

interface Filter {
  label: string;
  value: string;
}
interface Header {
  filter?: boolean;
  sortable?: boolean;
  id: string;
  label?: string;
  class: string;
  matchMode?: string;
  filterOptions?: Filter[];
  filterField?: string;
}

interface DataTabelFilter {
  id: string;
  operator: string;
  constraints: { value: null; matchMode: string }[];
}
interface DataTableReturnType {
  filters: Ref<{ [key: string]: DataTabelFilter }>;
}

export interface ExReachSimulatorOverview extends ReachSimulatorOverview {
  updatedAtDate: Date;
}

export const useSimulationProjectList = (companyId?: string) => {
  const router = useRouter();
  const route = useRoute();
  const simurationList = ref<ExReachSimulatorOverview[]>([]);

  const _fetchProjectList = async () => {
    try {
      if (!companyId) {
        toast({ title: 'カンパニーが選択されていません', variant: 'error' });
        simurationList.value = [];
        return;
      }
      const res = await ReachSimulatorApi.getCmPlanningCompaniesCompanyIdRsmProjects(
        Number(companyId)
      );
      if (200 <= res.status && res.status < 300) {
        const list = res.data.list.map(v => ({
          ...v,
          updatedAtDate: new Date(v.updatedAt)
        }));
        simurationList.value = await Promise.all(list);
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクト一覧の取得に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: 'プロジェクト一覧の取得に失敗しました',
        variant: 'error'
      });
    } finally {
      // ループ処理が画面遷移後に実行されていた場合停止
      setTimeout(async () => {
        if (isStopLoopRequest.value) stopLoopRequest(intervalId.value);
      }, 1000);
    }
  };
  const [isFetchProjectList, fetchProjectList] = useLoading(_fetchProjectList);

  const searchingList = computed(() => {
    return simurationList.value.filter(
      v =>
        v.status == ReachSimulatorOverviewStatusEnum.Simulating ||
        v.status == ReachSimulatorOverviewStatusEnum.SimulationQueued
    );
  });

  // ループ処理関連
  const intervalId = ref();
  const isStopLoopRequest = ref(false);
  const stopLoopRequest = id => {
    clearInterval(id);
  };
  const loopRequest = async () => {
    intervalId.value = setTimeout(async () => {
      if (!isFetchProjectList.value && searchingList.value.length > 0) {
        await fetchProjectList(companyId);
      }
      loopRequest();
    }, 10000);
  };

  const _deleteProject = async ({
    projectId,
    projectName
  }: {
    projectId: number;
    projectName: string;
  }) => {
    try {
      const res = await ReachSimulatorApi.deleteCmPlanningRsmProjectsProjectId(
        projectId
      );
      if (200 <= res.status && res.status < 300) {
        fetchProjectList(companyId);
        toast({
          title: '成功',
          message: '「 ' + projectName + '」を削除しました',
          variant: 'success'
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの削除に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e, 'プロジェクトの削除に失敗しました', '');
      } else if (e.status == 403) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの削除に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの削除に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isDeleteProjectList, deleteProject] = useLoading(_deleteProject);

  const getIconName = (value: ReachSimulatorOverviewStatusEnum) => {
    switch (value) {
      case ReachSimulatorOverviewStatusEnum.NeedToEdit:
        return 'exclamation';
      case ReachSimulatorOverviewStatusEnum.NotReferable:
        return 'ban';
      case ReachSimulatorOverviewStatusEnum.Simulating:
      case ReachSimulatorOverviewStatusEnum.SimulationQueued:
      case ReachSimulatorOverviewStatusEnum.Referable:
      default:
        return '';
    }
  };

  const gettoolTipMessage = (value: ReachSimulatorOverviewStatusEnum) => {
    switch (value) {
      case ReachSimulatorOverviewStatusEnum.NeedToEdit:
        return '利用できない条件が含まれています。編集画面で設定を変更してください。';
      case ReachSimulatorOverviewStatusEnum.NotReferable:
        return '予期せぬエラーが発生しました。サポートまでお問い合わせください。';
      case ReachSimulatorOverviewStatusEnum.Simulating:
      case ReachSimulatorOverviewStatusEnum.SimulationQueued:
      case ReachSimulatorOverviewStatusEnum.Referable:
      default:
        return '';
    }
  };

  const goToCreate = async () => {
    await router.push({
      name: PLANNING_ROUTES.simulationCreate,
      params: { ...route.params }
    });
  };

  const goToEdit = async (projectId: number) => {
    await router.push({
      name: PLANNING_ROUTES.simulationEdit,
      params: { ...route.params, projectId: projectId }
    });
  };

  const goToClone = async (projectId: number) => {
    await router.push({
      name: PLANNING_ROUTES.simulationClone,
      params: { ...route.params, projectId: projectId }
    });
  };

  const goToResult = async (projectId: number) => {
    await router.push({
      name: PLANNING_ROUTES.simulationResult,
      params: { ...route.params, projectId }
    });
  };

  return {
    simurationList,
    fetchProjectList,
    isFetchProjectList,
    deleteProject,
    isDeleteProjectList,
    getIconName,
    gettoolTipMessage,
    isStopLoopRequest,
    intervalId,
    stopLoopRequest,
    loopRequest,
    goToResult,
    goToCreate,
    goToClone,
    goToEdit
  };
};

const STR_FILTER: Filter[] = [
  { label: '等しい', value: FilterMatchMode.EQUALS },
  { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
  { label: '含む', value: FilterMatchMode.CONTAINS },
  { label: '含まない', value: FilterMatchMode.NOT_CONTAINS }
];
const NUM_FILTER: Filter[] = [
  { label: '以上', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
  { label: '以下', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
  { label: 'より大きい', value: FilterMatchMode.GREATER_THAN },
  { label: 'より小さい', value: FilterMatchMode.LESS_THAN }
];
const DATE_FILTER: Filter[] = [
  { label: '等しい', value: FilterMatchMode.DATE_IS },
  { label: '含まない', value: FilterMatchMode.DATE_IS_NOT },
  { label: '以前', value: FilterMatchMode.DATE_BEFORE },
  { label: 'より後', value: FilterMatchMode.DATE_AFTER }
];
export const HEADERS: Header[] = [
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'name',
    label: 'プロジェクト名',
    class: 'p-sortable-column--title',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'area',
    label: 'エリア',
    class: 'p-sortable-column--sm center',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'target',
    label: 'ターゲット',
    class: 'p-sortable-column--target',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'number',
    id: 'grp',
    label: '合計GRP',
    class: 'p-sortable-column--xsh end',
    matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    filterOptions: NUM_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'number',
    id: 'effectiveReach',
    label: 'Eリーチ人数',
    class: 'p-sortable-column--effectiveReach end',
    matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    filterOptions: NUM_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'number',
    id: 'placementCost',
    label: '合計出稿予算',
    class: 'p-sortable-column--smd end',
    matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    filterOptions: NUM_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'number',
    id: 'cpe',
    label: 'CPE',
    class: 'p-sortable-column--sm end',
    matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    filterOptions: NUM_FILTER
  },
  {
    id: 'conditions',
    label: '条件',
    class: 'p-sortable-column--ssm center'
  },
  {
    filter: true,
    sortable: true,
    filterField: 'date',
    id: 'updatedAtDate',
    label: '最終更新日',
    class: 'p-sortable-column--xsh center',
    matchMode: FilterMatchMode.DATE_IS,
    filterOptions: DATE_FILTER
  },
  {
    id: 'actions',
    class: 'p-sortable-column--sh last-colum'
  }
];

export const useDataTable = (): DataTableReturnType => {
  const filters = ref();
  const tmp = HEADERS.map(v => {
    if (!v.filter) return;
    return {
      id: v.id,
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: v.matchMode }]
    };
  }).filter(Boolean) as DataTabelFilter[];
  filters.value = tmp.reduce((p, c) => {
    return { ...p, [c.id]: c };
  }, {});

  return {
    filters
  };
};
