/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReachSimulatorApi } from '@/api';
import { ReachSimulatorProjectBulkFormBulkList } from '@/api/openapi';
import { roundNumber } from '@/common/formatter';
import { handleError } from '@/common/handleError';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { isDisabled } from '@/composables/validation';
import { ROUTE_NAMES } from '@/router';
import Axios from 'axios';
import { Ref, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export interface InputFormType {
  grpFrom: Ref<string | undefined>;
  grpTo: Ref<string | undefined>;
  interval: Ref<string | undefined>;
  grpList: Ref<number[]>;
}

const deleteComma = (str: string): string => {
  return str.replace(/,/g, '');
};

const castNum = (str: string | undefined): number | undefined => {
  if (!str) return;
  const val = deleteComma(str);
  if (!Number.isSafeInteger(Number(val))) {
    return 0;
  } else {
    return Number(val);
  }
};

// 入力フォーム
export const useBulkClone = (): any => {
  const router = useRouter();
  const route = useRoute();
  const companyId = route.params.companyId.toString();

  // 入力フォームデータ
  const inputform: InputFormType = {
    grpFrom: ref<string | undefined>(),
    grpTo: ref<string | undefined>(),
    interval: ref<string | undefined>(),
    grpList: ref<number[]>([])
  };

  // バリデーション
  const validGrps = computed(() => {
    const grpFrom = castNum(inputform.grpFrom.value);
    const grpTo = castNum(inputform.grpTo.value);

    if (grpFrom !== undefined && grpFrom !== null) {
      if (grpFrom < 100 || 20000 < grpFrom) {
        return VALIDATION_MESSAGE.invalidNumberWidth(100, 20000);
      }
    }

    if (grpTo !== undefined && grpFrom !== null) {
      if (grpTo < 100 || 20000 < grpTo) {
        return VALIDATION_MESSAGE.invalidNumberWidth(100, 20000);
      }
    }

    if ((!grpFrom && grpFrom !== 0) || (!grpTo && grpTo !== 0))
      return undefined;

    if (grpTo <= grpFrom) {
      return '数値の範囲指定が正しくありません';
    }

    if (0 <= grpFrom && grpFrom < grpTo && grpTo <= 20000) {
      return undefined;
    } else {
      return '0〜20,000の間で入力してください';
    }
  });

  const validInterval = computed(() => {
    const interval = castNum(inputform.interval.value);
    if (!interval && interval !== 0) return undefined;
    if (interval < 1 || 20000 < interval) {
      return VALIDATION_MESSAGE.invalidNumberWidth(1, 20000);
    }
    return undefined;
  });

  const validGrpList = computed(() => {
    const grpFrom = castNum(inputform.grpFrom.value);
    const grpTo = castNum(inputform.grpTo.value);
    const interval = castNum(inputform.interval.value);
    if (
      (!grpFrom && grpFrom !== 0) ||
      (!grpTo && grpTo !== 0) ||
      !interval ||
      validGrps.value ||
      validInterval.value
    )
      return undefined;

    if (inputform.grpList.value.length > 10) {
      return '一括複製できる最大数は10件です。10件以下になるように条件を変更してくだい。';
    }

    return undefined;
  });

  const disabled = isDisabled([validGrps, validInterval, validGrpList]);
  const isDisabledSubmit = computed(() => {
    return disabled.value || !(inputform.grpList.value.length > 0);
  });

  const setGrpFrom = () => {
    const grpFrom = castNum(inputform.grpFrom.value);
    const grpTo = castNum(inputform.grpTo.value);
    const interval = castNum(inputform.interval.value);
    if (
      grpFrom ||
      grpFrom === 0 ||
      (!grpTo && grpTo !== 0) ||
      (!interval && interval !== 0)
    ) {
      return;
    }
    let tmpFrom = grpTo;
    const tmpList: number[] = [];
    for (let i = 0; i <= grpTo; i++) {
      const n = tmpFrom - interval;
      if (n < 0) {
        if (tmpList.length === 0) tmpFrom = 0;
        break;
      } else {
        tmpList.push(tmpFrom);
      }
      if (tmpList.length === 10) {
        break;
      }
      tmpFrom -= 1;
    }
    if (validGrps.value || validInterval.value) {
      inputform.grpFrom.value = undefined;
    } else {
      inputform.grpFrom.value = roundNumber(tmpFrom);
    }

    setGrpList();
  };

  const setGrpTo = () => {
    const grpFrom = castNum(inputform.grpFrom.value);
    const grpTo = castNum(inputform.grpTo.value);
    const interval = castNum(inputform.interval.value);
    if (
      grpTo ||
      grpTo === 0 ||
      (!grpFrom && grpFrom !== 0) ||
      (!interval && interval !== 0)
    ) {
      return;
    }
    let tmpTo = grpFrom;
    const tmpList: number[] = [];
    for (let i = 0; i <= 20000; i++) {
      tmpList.push(tmpTo);
      if (tmpList.length === 10) {
        break;
      }
      const n = tmpTo + interval;
      if (20000 < n) break;
      tmpTo = n;
    }
    if (validGrps.value || validInterval.value) {
      inputform.grpTo.value = undefined;
    } else {
      inputform.grpTo.value = roundNumber(tmpTo);
    }

    setGrpList();
  };

  const setInterval = () => {
    const grpFrom = castNum(inputform.grpFrom.value);
    const grpTo = castNum(inputform.grpTo.value);
    const interval = castNum(inputform.interval.value);
    if (
      interval ||
      interval === 0 ||
      (!grpFrom && grpFrom !== 0) ||
      (!grpTo && grpTo !== 0)
    ) {
      return;
    }
    const oneNinth = (grpTo - grpFrom) / 9;
    let tmpInterval = oneNinth < 1 ? 1 : Math.floor(oneNinth);
    if (grpFrom + tmpInterval * 10 <= grpTo) {
      tmpInterval += 1;
    }
    if (validGrps.value || validInterval.value) {
      inputform.interval.value = undefined;
    } else {
      inputform.interval.value = roundNumber(tmpInterval);
    }

    setGrpList();
  };

  // 複製GRPリストの設定
  const setGrpList = () => {
    inputform.grpList.value = [];
    if (validGrps.value || validInterval.value) return;
    const grpFrom = castNum(inputform.grpFrom.value);
    const grpTo = castNum(inputform.grpTo.value);
    const interval = castNum(inputform.interval.value);
    if ((!grpFrom && grpFrom !== 0) || (!grpTo && grpTo !== 0) || !interval)
      return;

    let val = grpFrom;
    for (let i = 0; i < 11; i++) {
      inputform.grpList.value.push(val);
      val += interval;
      if (grpTo < val) {
        break;
      }
    }
  };

  const addGrpString = (name: string, grp: number) => {
    const addStr = '　（GRP：' + grp.toLocaleString() + '）';
    const tolerance = 64 - addStr.length;
    const newName =
      name.length > tolerance ? name.substring(0, tolerance) : name;
    return newName + addStr;
  };

  const _createProjectBulk = async ({
    name,
    projectId
  }: {
    name: string;
    projectId: number;
  }) => {
    const bulkList: ReachSimulatorProjectBulkFormBulkList[] = inputform.grpList.value.map(
      v => ({
        projectName: addGrpString(name, v),
        grp: v
      })
    );
    const BulkForm = {
      projectId: projectId,
      bulkList: bulkList
    };
    try {
      const res = await ReachSimulatorApi.postCmPlanningCompaniesCompanyIdRsmProjectsBulk(
        Number(companyId),
        BulkForm
      );
      if (200 <= res.status && res.status < 300) {
        router.go(0);
        toast({
          title: '成功',
          message: 'プロジェクトの一括複製に成功しました',
          variant: 'success'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e);
      } else if (e.status === 403) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの一括複製に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの一括複製に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isCreatingProjectBulk, createProjectBulk] = useLoading(
    _createProjectBulk
  );

  return {
    inputform,
    validGrps,
    validInterval,
    validGrpList,
    setGrpFrom,
    setGrpTo,
    setInterval,
    setGrpList,
    isDisabledSubmit,
    createProjectBulk,
    isCreatingProjectBulk
  };
};
